// localhost
// export const domain = "http://127.0.0.1:8000/api";
// export const resource = "https://sr-storage.sgp1.cdn.digitaloceanspaces.com/";

// អត់ប្រើ / នៅខាងចុងទេ
export const domain =
  "https://demo-manage-pos-api.oneiiotech.com/pos-api/public/api";
// export const resource = "https://sr-storage.sgp1.digitaloceanspaces.com/";
// export const domain = "https://manage-pos-spa-api.oneiiotech.com/pos-api/public/api";
// export const resource = "https://sr-storage.sgp1.digitaloceanspaces.com";
export const resource = "https://oneiiotech.sgp1.digitaloceanspaces.com";

//សម្រាប់ប្រើតាមហាងកាហ្វេ
// export const resource = "http://192.168.5.160:8000/api";
// export const domain = "http://192.168.5.160:8000";

// demo
// export const domain = "https://ntc.camkh.biz/pos-api/public/api";
// export const resource = "https://sr-storage.sgp1.digitaloceanspaces.com";
